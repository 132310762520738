export function getEnvVariable<T = any>(window: Partial<Window>) {
  return function (
    localName: string,
    envName: string
  ): T | boolean | undefined {
    const hasProperty = Object.prototype.hasOwnProperty.call(
      window,
      `${localName}`
    );
    if (!hasProperty)
      return import.meta.env[`VITE_APP_${envName}`] as T | undefined;
    const envValue = (window as { [key: string]: any })[`${localName}`];
    if (`${envValue}`.match(RegExp("true", "i"))) return true;
    if (`${envValue}`.match(RegExp("false", "i"))) return false;
    if (`${envValue}`.match(RegExp("^[a-zA-Z0-9]+"))) return envValue;
    return import.meta.env[`VITE_APP_${envName}`] as T | undefined;
  };
}
export const ENABLE_HOME_PROJECT_SELECTOR_FRONTEND =
  getEnvVariable<boolean>(window)(
    "ENABLE_HOME_PROJECT_SELECTOR_FRONTEND",
    "ENABLE_HOME_PROJECT_SELECTOR_FRONTEND"
  ) || false;
export const ENABLE_REPORT_TEMPLATE_MANAGEMENT_FRONTEND =
  getEnvVariable<boolean>(window)(
    "ENABLE_REPORT_TEMPLATE_MANAGEMENT_FRONTEND",
    "ENABLE_REPORT_TEMPLATE_MANAGEMENT_FRONTEND"
  ) || false;
export const APP_API_BASE_URL =
  (getEnvVariable(window)("APP_API_BASE_URL", "BASE_URL") as string) || "";

export const ENABLE_POLL_DELETE =
  getEnvVariable<boolean>(window)("ENABLE_POLL_DELETE", "ENABLE_POLL_DELETE") ||
  true;

export const WEB_VERSION =
  (getEnvVariable(window)("WEB_VERSION", "WEB_VERSION") as string) || "1.0.0";

export const YEAR_VERSION =
  (getEnvVariable(window)("YEAR_VERSION", "YEAR_VERSION") as string) || "";
export const GOOGLEMAP_API_KEY = getEnvVariable(window)(
  "GOOGLEMAP_API_KEY",
  "GOOGLEMAP_API_KEY"
) as string;
export const GEOSERVER_STORE = (function (): string {
  const location = window.location.hostname;
  const subDomain = location.split(".")[0] || "";
  return subDomain === "tmopr" ? "production" : subDomain;
})();
export const GEOSERVER_WMS_URL = `${window.location.origin}/geoserver/wms`;

export const NODE_ENV = process.env.NODE_ENV;
