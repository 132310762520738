import AppErrorDispatcher from "@/components/organisms/AppErrorDispatcher";
import { PAGE_URL } from "@/data_source/constants";
import AppErrorPage from "@/pages/AppErrorPage";
import AppLoading from "@/pages/AppLoading";
import AppNotFoundPage from "@/pages/AppNotFoundPage";
import AppPasswordRecovery from "@/pages/AppPasswordRecovery";
import AppSignIn from "@/pages/AppSignIn";
import { ReactNode, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import {
  AppAssetItemsList,
  AppAuditManagement,
  AppAuditManagementAnswerNew,
  AppAuditManagementAnswerUpdate,
  AppAuditManagementDashboard,
  AppAuditManagementDetail,
  AppAuditManagementExport,
  AppAuditManagementExternalData,
  AppAuditManagementForms,
  AppAuditManagementList,
  AppAuditManagementMap,
  AppAuditManagementResponse,
  AppBatteriesManagement,
  AppBatteriesManagementAssignBattery,
  AppBatteriesManagementBatteryHistory,
  AppBatteriesManagementBatteryHistoryDetail,
  AppBatteriesManagementDashboard,
  AppBatteriesManagementDeleteBatteries,
  AppBatteriesManagementDetailBattery,
  AppBatteriesManagementExport,
  AppBatteriesManagementExportLoader,
  AppBatteriesManagementImport,
  AppBatteriesManagementInstallBattery,
  AppBatteriesManagementSiteDetail,
  AppBatteriesManagementStockControl,
  AppBatteriesManagementSummary,
  AppBreadcrumbDashboardDelete,
  AppBreadcrumbDashboardManagement,
  AppBreadcrumbDashboardNew,
  AppBreadcrumbDashboardUpdate,
  AppCategoryPollDelete,
  AppCategoryPollList,
  AppCategoryPollNew,
  AppCategoryPollUpdate,
  AppDashboard,
  AppDriveTestControl,
  AppDriveTestControlCollectionCycleNew,
  AppDriveTestControlCollectionCycleUpdate,
  AppDriveTestControlDashboard,
  AppDriveTestControlNew,
  AppDriveTestControlSettings,
  AppDriveTestControlUpdate,
  AppEntityDetail,
  AppEquipmentManagement,
  AppEquipmentManagementStockControl,
  AppFormManagement,
  AppFormManagementAllForm,
  AppFormManagementAllFormsFavorites,
  AppFormManagementCategories,
  AppFormManagementTeams,
  AppInboxManagementList,
  AppLayoutDashboardDelete,
  AppLayoutDashboardManagement,
  AppLayoutDashboardNew,
  AppLayoutDashboardUpdate,
  AppLayoutDashboardWidget,
  AppLayoutDashboardWidgetDelete,
  AppLayoutDashboardWidgetNew,
  AppLayoutDashboardWidgetUpdate,
  AppLibraryDashboard,
  AppNetworkElement,
  AppNetworkElementFormBasic,
  AppNetworkElementFormBegin,
  AppNetworkElementFormCustom,
  AppNetworkElementFormFinish,
  AppNetworkElementFormModules,
  AppNetworkElementFormSystem,
  AppNetworkForm,
  AppNetworkFormBuilder,
  AppNewModelsList,
  AppNewNetworkElementList,
  AppNewPageBuilder,
  AppPageTest,
  AppPictureManagement,
  AppPollAnswerDelete,
  AppPollDelete,
  AppPollImport,
  AppPollNew,
  AppPollUpdate,
  AppProjectManagement,
  AppReportNewFunctionality,
  AppReportTemplateManagement,
  AppRolManagementCreate,
  AppRolesManagementList,
  AppSettings,
  AppSettingsMap,
  AppSettingsOthers,
  AppSignUp,
  AppSignUpCheckbox,
  AppSignUpCodeConfirmation,
  AppSignUpProject,
  AppSiteView,
  AppSiteVisits,
  AppSiteVisitsDashboard,
  AppSiteVisitsDetails,
  AppSiteVisitsGroupRealtimeDelete,
  AppSiteVisitsGroupRealtimeNew,
  AppSiteVisitsGroupRealtimeUpdate,
  AppSiteVisitsLive,
  AppSiteVisitsLiveView,
  AppSiteVisitsWorkInProgress,
  AppTeamsManagement,
  AppUserManagement,
  AppUserManagementActivity,
  AppUserManagementBusiness,
  AppUserManagementBusinessDelete,
  AppUserManagementBusinessNew,
  AppUserManagementBusinessUpdate,
  AppUserManagementChangePassword,
  AppUserManagementCompany,
  AppUserManagementCompanyDelete,
  AppUserManagementCompanyNew,
  AppUserManagementCompanyUpdate,
  AppUserManagementDelete,
  AppUserManagementNew,
  AppUserManagementProfilesDelete,
  AppUserManagementSecurity,
  AppUserManagementUpdate,
  AppUserProfile,
  AppWorkOrder,
  Home
} from "./lazyLoadedComponents";

export type RouteAppComponentProps = {
  pageName?: string;
};

function suspenseWrapper(
  Component: (props: RouteAppComponentProps) => JSX.Element | ReactNode | null
) {
  return (props: RouteAppComponentProps): JSX.Element => (
    <Suspense fallback={<AppLoading />}>
      {Component && <Component {...props} />}
    </Suspense>
  );
}
const routes = createBrowserRouter([
  {
    path: PAGE_URL.SIGN_UP,
    element: suspenseWrapper(AppSignUp)({ pageName: "SignUp" }),
    children: []
  },
  {
    path: PAGE_URL.SIGN_UP_CODE_CONFIRMATION,
    element: suspenseWrapper(AppSignUpCodeConfirmation)({
      pageName: "Code Confirmation"
    }),
    children: []
  },
  {
    path: PAGE_URL.SIGN_UP_CHECKBOX,
    element: suspenseWrapper(AppSignUpCheckbox)({ pageName: "Checkbox Jobs" }),
    children: []
  },
  {
    path: PAGE_URL.SIGN_UP_PROJECT,
    element: suspenseWrapper(AppSignUpProject)({
      pageName: "Project Confirmation"
    }),
    children: []
  },
  {
    path: PAGE_URL.SIGN_IN,
    element: suspenseWrapper(AppSignIn)({ pageName: "" })
  },
  {
    path: PAGE_URL.PASSWORD_RECOVERY,
    element: suspenseWrapper(AppPasswordRecovery)({
      pageName: "Recovery password"
    })
  },
  {
    path: PAGE_URL.HOME,
    element: suspenseWrapper(Home)({ pageName: "" }),
    children: [
      {
        path: PAGE_URL.ERROR_404,
        element: <AppNotFoundPage pageName="Not Found" />
      },
      {
        path: PAGE_URL.ERROR_PAGE,
        element: <AppErrorPage pageName="Error Page" />
      },
      {
        path: PAGE_URL.HOME,
        element: suspenseWrapper(AppDashboard)({ pageName: "" })
      },
      {
        path: PAGE_URL.FORMS_MANAGEMENT,
        element: suspenseWrapper(AppFormManagement)({
          pageName: "Forms Management"
        }),
        children: [
          {
            path: PAGE_URL.FORMS_MANAGEMENT_CATEGORIES,
            element: suspenseWrapper(AppFormManagementCategories)({
              pageName: "Forms Management: Categories"
            })
          },
          {
            path: PAGE_URL.FORMS_MANAGEMENT_ALL,
            element: suspenseWrapper(AppFormManagementAllForm)({
              pageName: "Forms Management: All forms"
            })
          },
          {
            path: PAGE_URL.FORMS_MANAGEMENT_TEAMS,
            element: suspenseWrapper(AppFormManagementTeams)({
              pageName: "Forms Management: Teams"
            })
          },
          {
            path: PAGE_URL.FORMS_MANAGEMENT_ALL_FAVORITES,
            element: suspenseWrapper(AppFormManagementAllFormsFavorites)({
              pageName: "Form Management Favorites"
            })
          }
        ]
      },
      {
        path: PAGE_URL.POLL_NEW,
        element: suspenseWrapper(AppPollNew)({ pageName: "New Poll" })
      },
      {
        path: PAGE_URL.POLL_IMPORT,
        element: suspenseWrapper(AppPollImport)({ pageName: "Import Poll" })
      },
      {
        path: PAGE_URL.POLL_UPDATE,
        element: suspenseWrapper(AppPollUpdate)({ pageName: "Update Forms" })
      },
      {
        path: PAGE_URL.POLL_DELETE,
        element: suspenseWrapper(AppPollDelete)({ pageName: "Delete Form" })
      },
      {
        path: PAGE_URL.AUDIT_MANAGEMENT_DETAILS,
        element: suspenseWrapper(AppAuditManagementDetail)({
          pageName: "Audit Details"
        })
      },
      {
        path: PAGE_URL.AUDIT_MANAGEMENT_UPDATE_ANSWER,
        element: suspenseWrapper(AppAuditManagementAnswerUpdate)({
          pageName: "Update Poll Answer"
        })
      },
      {
        path: PAGE_URL.POLL_ANSWER_DELETE,
        element: suspenseWrapper(AppPollAnswerDelete)({
          pageName: "Poll Answer Delete"
        })
      },
      {
        path: PAGE_URL.AUDIT_MANAGEMENT_NEW_ANSWER,
        element: suspenseWrapper(AppAuditManagementAnswerNew)({
          pageName: "New Poll Answer"
        })
      },
      {
        path: PAGE_URL.AUDIT_MANAGEMENT_EXTERNAL_DATA,
        element: suspenseWrapper(AppAuditManagementExternalData)({
          pageName: "Form External Data & Images"
        })
      },
      {
        path: PAGE_URL.DRIVE_TEST_CONTROL_CONFIG,
        element: suspenseWrapper(AppDriveTestControlSettings)({
          pageName: "Drive Test Control Settings"
        })
      },
      {
        path: PAGE_URL.DRIVE_TEST_CONTROL,
        element: suspenseWrapper(AppDriveTestControl)({
          pageName: "Drive Test Control"
        }),
        children: [
          {
            path: PAGE_URL.DRIVE_TEST_CONTROL_DASHBOARD,
            element: suspenseWrapper(AppDriveTestControlDashboard)({
              pageName: "Drive Test Control"
            })
          },
          {
            path: PAGE_URL.DRIVE_TEST_CONTROL_NEW,
            element: suspenseWrapper(AppDriveTestControlNew)({
              pageName: "Drive Test Control New"
            })
          },
          {
            path: PAGE_URL.DRIVE_TEST_CONTROL_UPDATE,
            element: suspenseWrapper(AppDriveTestControlUpdate)({
              pageName: "Drive Test Control Update"
            })
          },
          {
            path: PAGE_URL.DRIVE_TEST_CONTROL_COLLECTION_CYCLE_NEW,
            element: suspenseWrapper(AppDriveTestControlCollectionCycleNew)({
              pageName: "Drive Test Control Collection Cycle New"
            })
          },
          {
            path: PAGE_URL.DRIVE_TEST_CONTROL_COLLECTION_CYCLE_UPDATE,
            element: suspenseWrapper(AppDriveTestControlCollectionCycleUpdate)({
              pageName: "Drive Test Control Collection Cycle Update"
            })
          }
        ]
      },
      {
        path: PAGE_URL.AUDIT_MANAGEMENT,
        element: suspenseWrapper(AppAuditManagement)({
          pageName: "Audit Management"
        }),
        children: [
          {
            path: "",
            element: suspenseWrapper(AppAuditManagementResponse)({
              pageName: "Audit Management"
            }),
            children: [
              {
                path: PAGE_URL.AUDIT_MANAGEMENT_LIST,
                element: suspenseWrapper(AppAuditManagementList)({
                  pageName: "Audit Management"
                })
              },
              {
                path: PAGE_URL.AUDIT_MANAGEMENT_MAP,
                element: suspenseWrapper(AppAuditManagementMap)({
                  pageName: "Audit Management"
                })
              }
            ]
          },
          {
            path: PAGE_URL.AUDIT_MANAGEMENT_FORMS,
            element: suspenseWrapper(AppAuditManagementForms)({
              pageName: "Audit Management"
            })
          },
          {
            path: PAGE_URL.AUDIT_MANAGEMENT_DASHBOARD,
            element: suspenseWrapper(AppAuditManagementDashboard)({
              pageName: "Audit Management"
            })
          },
          {
            path: PAGE_URL.AUDIT_MANAGEMENT_EXPORT,
            element: suspenseWrapper(AppAuditManagementExport)({
              pageName: "Audit Management Export"
            })
          },
          {
            path: "*",
            element: <AppErrorDispatcher errorStatusCode={404} />
          }
        ]
      },
      {
        path: PAGE_URL.CATEGORY_POLL_LIST,
        element: suspenseWrapper(AppCategoryPollList)({
          pageName: "Category Poll Management"
        })
      },
      {
        path: PAGE_URL.CATEGORY_POLL_NEW,
        element: suspenseWrapper(AppCategoryPollNew)({
          pageName: "New Category"
        })
      },
      {
        path: PAGE_URL.CATEGORY_POLL_UPDATE,
        element: suspenseWrapper(AppCategoryPollUpdate)({
          pageName: "Update Category"
        })
      },
      {
        path: PAGE_URL.CATEGORY_POLL_DELETE,
        element: suspenseWrapper(AppCategoryPollDelete)({
          pageName: "Delete Category"
        })
      },

      {
        path: PAGE_URL.MAINTENANCE_AUDIT_FORM,
        element: <h1>Work in Progress</h1>
      },
      {
        path: PAGE_URL.TEAMS_MANAGEMENT,
        element: suspenseWrapper(AppTeamsManagement)({
          pageName: "Team Management"
        })
      },
      {
        path: PAGE_URL.PROJECT_MANAGEMENT,
        element: suspenseWrapper(AppProjectManagement)({
          pageName: "Project Management"
        })
      },
      {
        path: PAGE_URL.REPORT_TEMPLATE_LIST,
        element: suspenseWrapper(AppReportTemplateManagement)({
          pageName: "Report Template Management"
        })
      },
      {
        path: PAGE_URL.REPORT_NEW_FUNCTIONALITY,
        element: suspenseWrapper(AppReportNewFunctionality)({
          pageName: "Report Template Management"
        })
      },
      {
        path: PAGE_URL.BREADCRUMB_DASHBOARD_MANAGEMENT,
        element: suspenseWrapper(AppBreadcrumbDashboardManagement)({
          pageName: "Dashboard Management"
        })
      },
      {
        path: PAGE_URL.NEW_BREADCRUMB_DASHBOARD,
        element: suspenseWrapper(AppBreadcrumbDashboardNew)({
          pageName: "Dashboard Management New Breadcrumb"
        })
      },
      {
        path: PAGE_URL.UPDATE_BREADCRUMB_DASHBOARD,
        element: suspenseWrapper(AppBreadcrumbDashboardUpdate)({
          pageName: "Dashboard Management Update Breadcrumb"
        })
      },
      {
        path: PAGE_URL.DELETE_BREADCRUMB_DASHBOARD,
        element: suspenseWrapper(AppBreadcrumbDashboardDelete)({
          pageName: "Dashboard Management Delete Breadcrumb"
        })
      },
      {
        path: PAGE_URL.INBOX_MANAGEMENT_LIST,
        element: suspenseWrapper(AppInboxManagementList)({
          pageName: "Inbox List"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_MANAGEMENT,
        element: suspenseWrapper(AppLayoutDashboardManagement)({
          pageName: "Dashboard Management"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_UPDATE,
        element: suspenseWrapper(AppLayoutDashboardUpdate)({
          pageName: "Update Dashboard"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_NEW,
        element: suspenseWrapper(AppLayoutDashboardNew)({
          pageName: "New Dashboard"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_DELETE,
        element: suspenseWrapper(AppLayoutDashboardDelete)({
          pageName: "Delete Dashboard"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_WIDGETS,
        element: suspenseWrapper(AppLayoutDashboardWidget)({
          pageName: "Widget Management"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_WIDGETS_NEW,
        element: suspenseWrapper(AppLayoutDashboardWidgetNew)({
          pageName: "New Widget"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_WIDGETS_UPDATE,
        element: suspenseWrapper(AppLayoutDashboardWidgetUpdate)({
          pageName: "Update Widget"
        })
      },
      {
        path: PAGE_URL.LAYOUT_DASHBOARD_WIDGETS_DELETE,
        element: suspenseWrapper(AppLayoutDashboardWidgetDelete)({
          pageName: "Delete Widget"
        })
      },
      {
        path: PAGE_URL.SETTINGS,
        element: suspenseWrapper(AppSettings)({
          pageName: "Settings"
        })
      },
      {
        path: PAGE_URL.SETTINGS_OTHERS,
        element: suspenseWrapper(AppSettingsOthers)({
          pageName: "Other Settings"
        })
      },
      {
        path: PAGE_URL.USER_MANAGEMENT,
        element: suspenseWrapper(AppUserManagement)({
          pageName: "User Management"
        })
      },
      {
        path: PAGE_URL.NEW_USER,
        element: suspenseWrapper(AppUserManagementNew)({
          pageName: "New User"
        })
      },
      {
        path: PAGE_URL.UPDATE_USER,
        element: suspenseWrapper(AppUserManagementUpdate)({
          pageName: "Update User"
        })
      },
      {
        path: PAGE_URL.CHANGE_PASSWORD,
        element: suspenseWrapper(AppUserManagementChangePassword)({
          pageName: "Change Password"
        })
      },
      {
        path: PAGE_URL.USER_DELETE,
        element: suspenseWrapper(AppUserManagementDelete)({
          pageName: "Delete User"
        })
      },
      {
        path: PAGE_URL.USER_ACTIVITY,
        element: suspenseWrapper(AppUserManagementActivity)({
          pageName: "User Activities"
        })
      },
      {
        path: PAGE_URL.MY_PROFILE,
        element: suspenseWrapper(AppUserProfile)({
          pageName: "User Profile"
        })
      },
      {
        path: PAGE_URL.USER_PROFILE,
        element: suspenseWrapper(AppRolesManagementList)({
          pageName: "Profile Management"
        })
      },
      {
        path: PAGE_URL.USER_PROFILE_NEW,
        element: suspenseWrapper(AppRolManagementCreate)({
          pageName: "New Profile"
        })
      },
      {
        path: PAGE_URL.USER_PROFILE_UPDATE,
        element: suspenseWrapper(AppRolManagementCreate)({
          pageName: "Update Profile"
        })
      },
      {
        path: PAGE_URL.USER_PROFILE_DELETE,
        element: suspenseWrapper(AppUserManagementProfilesDelete)({
          pageName: "Delete Profile"
        })
      },
      {
        path: PAGE_URL.USER_BUSINESS,
        element: suspenseWrapper(AppUserManagementBusiness)({
          pageName: "Business Management"
        })
      },
      {
        path: PAGE_URL.USER_BUSINESS_NEW,
        element: suspenseWrapper(AppUserManagementBusinessNew)({
          pageName: "New Business"
        })
      },
      {
        path: PAGE_URL.USER_BUSINESS_UPDATE,
        element: suspenseWrapper(AppUserManagementBusinessUpdate)({
          pageName: "Update Business"
        })
      },
      {
        path: PAGE_URL.USER_BUSINESS_DELETE,
        element: suspenseWrapper(AppUserManagementBusinessDelete)({
          pageName: "Delete Business"
        })
      },
      {
        path: PAGE_URL.USER_COMPANY,
        element: suspenseWrapper(AppUserManagementCompany)({
          pageName: "Own Company Management"
        })
      },
      {
        path: PAGE_URL.USER_COMPANY_NEW,
        element: suspenseWrapper(AppUserManagementCompanyNew)({
          pageName: "New Own Company"
        })
      },
      {
        path: PAGE_URL.USER_COMPANY_UPDATE,
        element: suspenseWrapper(AppUserManagementCompanyUpdate)({
          pageName: "Update Own Company"
        })
      },
      {
        path: PAGE_URL.USER_COMPANY_DELETE,
        element: suspenseWrapper(AppUserManagementCompanyDelete)({
          pageName: "Delete Own Company"
        })
      },
      {
        path: PAGE_URL.USER_SECURITY,
        element: suspenseWrapper(AppUserManagementSecurity)({
          pageName: "User Security"
        })
      },
      {
        path: PAGE_URL.BATTERIES_MANAGEMENT,
        element: suspenseWrapper(AppBatteriesManagement)({
          pageName: "Batteries Management"
        }),
        children: [
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_DASHBOARD,
            element: suspenseWrapper(AppBatteriesManagementDashboard)({
              pageName: "Batteries Dashboard"
            })
          },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_SUMMARY,
            element: suspenseWrapper(AppBatteriesManagementSummary)({
              pageName: "Summary"
            })
          },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_STOCK_CONTROL,
            element: suspenseWrapper(AppBatteriesManagementStockControl)({
              pageName: "Stock Control"
            })
          },
          // {
          //   path: PAGE_URL.BATTERIES_MANAGEMENT_ADD_BATTERY,
          //   element: suspenseWrapper(AppBatteriesManagementAddBattery),
          // },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_INSTALL_BATTERY,
            element: suspenseWrapper(AppBatteriesManagementInstallBattery)({
              pageName: "Install Battery"
            })
          },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_ASSIGN_BATTERY,
            element: suspenseWrapper(AppBatteriesManagementAssignBattery)({
              pageName: "Assign Battery"
            })
          },
          // {
          //   path: PAGE_URL.BATTERIES_MANAGEMENT_RETURN_BATTERY,
          //   element: suspenseWrapper(AppBatteriesManagementReturnBattery),
          // },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_BATTERY_HISTORY,
            element: suspenseWrapper(AppBatteriesManagementBatteryHistory)({
              pageName: "Battery History"
            })
          },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_IMPORT_BATTERY,
            element: suspenseWrapper(AppBatteriesManagementImport)({
              pageName: "Import Battery"
            })
          },
          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_EXPORT_BATTERY,
            element: suspenseWrapper(AppBatteriesManagementExport)({
              pageName: "Export Battery"
            })
          },

          {
            path: PAGE_URL.BATTERIES_MANAGEMENT_DELETE_BATTERY,
            element: suspenseWrapper(AppBatteriesManagementDeleteBatteries)({
              pageName: "Delete Batteries"
            })
          }
        ]
      },
      {
        path: PAGE_URL.EQUIPMENTS_MANAGEMENT,
        element: suspenseWrapper(AppEquipmentManagement)({
          pageName: "Equipments Management"
        }),
        children: [
          {
            path: PAGE_URL.EQUIPMENTS_MANAGEMENT_STOCK_CONTROL,
            element: suspenseWrapper(AppEquipmentManagementStockControl)({
              pageName: "Stock Control"
            })
          }
        ]
      },
      {
        path: PAGE_URL.SITE_VIEW,
        element: suspenseWrapper(AppSiteView)({
          pageName: "Site View"
        })
      },
      {
        path: PAGE_URL.MAP,
        element: suspenseWrapper(AppSettingsMap)({
          pageName: "Settings Map"
        })
      },
      {
        path: PAGE_URL.BATTERIES_MANAGEMENT_DETAIL_BATTERY,
        element: suspenseWrapper(AppBatteriesManagementDetailBattery)({
          pageName: "Details Battery"
        })
      },
      {
        path: PAGE_URL.BATTERIES_MANAGEMENT_DETAIL_SITE,
        element: suspenseWrapper(AppBatteriesManagementSiteDetail)({
          pageName: "Details Site"
        })
      },
      {
        path: PAGE_URL.BATTERIES_MANAGEMENT_BATTERY_HISTORY_DETAIL,
        element: suspenseWrapper(AppBatteriesManagementBatteryHistoryDetail)({
          pageName: "Details History"
        })
      },
      {
        path: PAGE_URL.SITE_VISITS,
        element: suspenseWrapper(AppSiteVisits)({
          pageName: "Site Visits"
        }),
        children: [
          {
            path: PAGE_URL.SITE_VISITS_DASHBOARD,
            element: suspenseWrapper(AppSiteVisitsDashboard)({
              pageName: "Site Visits|Dashboard"
            })
          },
          {
            path: PAGE_URL.SITE_VISITS_LIST,
            element: suspenseWrapper(AppSiteVisitsDetails)({
              pageName: "Site Visits|Details"
            })
          },
          {
            path: PAGE_URL.SITE_VISITS_WORK_IN_PROGRESS,
            element: suspenseWrapper(AppSiteVisitsWorkInProgress)({
              pageName: "Site Visits|Work in progress"
            })
          },
          {
            path: PAGE_URL.SITE_VISITS_TRACKER_LIST,
            element: suspenseWrapper(AppSiteVisitsLive)({
              pageName: "Site |Real field visits"
            })
          },
          {
            path: PAGE_URL.SITE_VISITS_REAL_TIME_LIST,
            element: suspenseWrapper(AppSiteVisitsLiveView)({
              pageName: "Site Visits|Real time tracker"
            })
          },
          {
            path: PAGE_URL.SITE_VISITS_BROADCASTER,
            element: suspenseWrapper(AppInboxManagementList)({
              pageName: "Site Visits|Inbox"
            })
          }
        ]
      },
      {
        path: PAGE_URL.GROUP_REALTIME_NEW,
        element: suspenseWrapper(AppSiteVisitsGroupRealtimeNew)({
          pageName: "Realtime Group"
        })
      },
      {
        path: PAGE_URL.GROUP_REALTIME_DELETE,
        element: suspenseWrapper(AppSiteVisitsGroupRealtimeDelete)({
          pageName: "Realtime Group"
        })
      },
      {
        path: PAGE_URL.GROUP_REALTIME_UPDATE,
        element: suspenseWrapper(AppSiteVisitsGroupRealtimeUpdate)({
          pageName: "Realtime Group"
        })
      },
      {
        path: PAGE_URL.BATTERIES_MANAGEMENT_EXPORT_LOADER,
        element: suspenseWrapper(AppBatteriesManagementExportLoader)({
          pageName: "Export Battery Loader"
        })
      },
      {
        path: PAGE_URL.MODEL_VIEWS,
        element: suspenseWrapper(AppAssetItemsList)({
          pageName: "App Model View"
        })
      },
      {
        path: PAGE_URL.ENTITY_DETAIL,
        element: suspenseWrapper(AppEntityDetail)({
          pageName: "App Entity Detail"
        })
      },

      {
        path: PAGE_URL.NETWORK_ELEMENT,
        element: suspenseWrapper(AppNetworkElement)({
          pageName: "Network Element"
        }),
        children: []
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_BEGIN,
        element: suspenseWrapper(AppNetworkElementFormBegin)({
          pageName: "Network Element Begin"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_BASIC_CARDS,
        element: suspenseWrapper(AppNetworkElementFormBasic)({
          pageName: "Network Element Basic"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_CUSTOM_CARDS,
        element: suspenseWrapper(AppNetworkElementFormCustom)({
          pageName: "Network Element Custom"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_SYSTEM_CARDS,
        element: suspenseWrapper(AppNetworkElementFormSystem)({
          pageName: "Network Element System"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_MODULES,
        element: suspenseWrapper(AppNetworkElementFormModules)({
          pageName: "Network Element Modules"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_DETAILS,
        element: suspenseWrapper(AppNewModelsList)({
          pageName: "Network Element Details"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_FINISH,
        element: suspenseWrapper(AppNetworkElementFormFinish)({
          pageName: "Network Element Begin"
        })
      },
      {
        path: PAGE_URL.WORK_ORDER,
        element: suspenseWrapper(AppWorkOrder)({
          pageName: "Work Order"
        })
      },
      {
        path: PAGE_URL.LIBRARY_DASHBOARD,
        element: suspenseWrapper(AppLibraryDashboard)({
          pageName: "Library Dashboard"
        })
      },
      {
        path: PAGE_URL.LIBRARY_FOLDER,
        element: suspenseWrapper(AppLibraryDashboard)({
          pageName: "Library Folder"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_PAGEBUILDER_NEW,
        element: suspenseWrapper(AppNewPageBuilder)({
          pageName: "New Pagebuilder"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_PAGEBUILDER_EDIT,
        element: suspenseWrapper(AppNewPageBuilder)({
          pageName: "Edit Pagebuilder"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORMBUILDER_NEW,
        element: suspenseWrapper(AppNetworkFormBuilder)({
          pageName: "New FormBuilder"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORMBUILDER_NEW_EDIT,
        element: suspenseWrapper(AppNetworkFormBuilder)({
          pageName: "New FormBuilder Edit"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_NEW,
        element: suspenseWrapper(AppNetworkForm)({
          pageName: "New Form Create"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_FORM_NEW_EDIT,
        element: suspenseWrapper(AppNetworkForm)({
          pageName: "New Form Edit"
        })
      },
      {
        path: PAGE_URL.NETWORK_ELEMENT_LIST,
        element: suspenseWrapper(AppNewNetworkElementList)({
          pageName: "Network Element"
        })
      },
      {
        path: PAGE_URL.PICTURE_MANAGEMENT,
        element: suspenseWrapper(AppPictureManagement)({
          pageName: "Picture Manager"
        })
      },
      {
        path: "/lab-test",
        element: suspenseWrapper(AppPageTest)({
          pageName: "Test"
        })
      },
      {
        path: "*",
        element: <AppErrorDispatcher errorStatusCode={404} />
      }
    ]
  }
]);

export default routes;
